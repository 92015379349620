
.rangeSlider {
    margin-top: 0.25rem;
  }
  
  .rangeSlider {
    width: 100%;
  }
  
  .rangeSliderRange {
    -webkit-appearance: none;
    width: calc(100% - (73px));
    height: 10px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 0;
  }
  .rangeSliderRange::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #A5A5A5;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
  }
  .rangeSliderRange::-webkit-slider-thumb:hover {
    background: #3D78DC;
  }
  .rangeSliderRange:active::-webkit-slider-thumb {
    background: #3D78DC;
  }
  .rangeSliderRange::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 0;
    border-radius: 50%;
    background: #3D78DC;
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
  }
  .rangeSliderRange::-moz-range-thumb:hover {
    background: #3D78DC;
  }
  .rangeSliderRange:active::-moz-range-thumb {
    background: #3D78DC;
  }
  .rangeSliderRange:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #3D78DC;
  }
  
  .rangeSliderValue {
    display: inline-block;
    position: relative;
    width: 60px;
    color: #fff;
    line-height: 15px;
    text-align: center;
    border-radius: 3px;
    background: #3D78DC;
    padding: 5px 10px;
    margin-left: 0.5rem;
  }
  .rangeSliderValue:after {
    position: absolute;
    top: 6px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid #3D78DC;
    border-bottom: 7px solid transparent;
    content: "";
  }
  
  ::-moz-range-track {
    background: #d7dcdf;
    border: 0;
  }